<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {errorCatcher} from "../../../../helpers/error-catcher";

export default {

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      }
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.customers.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "email", label: "Adres Email" },
        { key: "firstName", label: "Imię" },
        { key: "lastName", label: "Nazwisko" },
        { key: "deliveryFirstName", label: "Imię (adres dostawy)" },
        { key: "deliveryLastName", label: "Nazwisko (adres dostawy)" },
        { key: "companyName", label: "Firma" },
        { key: "street", label: "Ulica" },
        { key: "postCode", label: "Kod pocztowy" },
        { key: "city", label: "Miasto" },
        { key: "phoneNumber", label: "Nr telefonu" },
        { key: "invoice", slot: true, label: "Dane do faktury" },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    async loadCustomers() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/customer/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter
          }
        });

        this.table.items = data.customers
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.customers.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadCustomers"
                :pagination-top="true"
            >
              <template v-slot:invoice="{ item }">
                <span>{{ item.invoiceFirstName }} {{ item.invoiceLastName }}</span>
                <br />
                <span>{{ item.invoiceStreet }}</span>
                <br />
                <span>{{ item.invoicePostCode }} {{ item.invoiceCity }}</span>
              </template>

              <template v-slot:action="{ item }">
                <a :href="`/dashboard/core/customers/orders/${item.id}`" class="btn btn-primary btn-sm">Zamówienia</a>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>